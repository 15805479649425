export const environment = {
  production: false,
  url: 'https://onboardingdev.immopad.com',
  app_url: {
    appstore: 'https://apps.apple.com/fr/app/immopad/id978837017',
    googleplay: 'https://play.google.com/store/apps/details?id=com.immopad.immopad_hd'
  },
  api: {
    url: 'https://apionboardingdev.immopad.com'
  },
  langs: ["en", "fr"],
  default_country: "fr",
  default_lang: "FR",
  default_symbol: "€",
  default_currency: "eur",
  default_tax: 0,
  default_tax_id: "",
  premium_form: "https://immopad.com/abonnement-immopad-premium-preparons-votre-devis/",
  stripeKey: "pk_test_51LD7MfIgvTRBb2KFlOwpJFzRVt9IXcy1RIbaVVM3xOz2DjU601xY4XQUHFqULPWPQCQKt896Grq9auT21my3NMt900vdfgsZG0"
};
