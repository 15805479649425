import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'onboarding-web';

  constructor(translate: TranslateService, private apiService: ApiService, private authService: AuthService) {
    var userLang = navigator.language;
    translate.addLangs(environment.langs);
    translate.setDefaultLang("en");

    if (userLang && userLang.length >= 2) {
      userLang = userLang.slice(0, 2)
    }

    if (sessionStorage.getItem("language")) {
      userLang = sessionStorage.getItem("language");
    }

    if (environment.langs.includes(userLang)) {
      translate.use(userLang);
      sessionStorage.setItem("language", userLang);
    } else {
      translate.use("en");
      sessionStorage.setItem("language", "en");
    }
  }

}
