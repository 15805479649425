import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  redirectUrl: string = '/';
  token: string = sessionStorage.getItem('token');

  constructor() { }

  isLoggedIn(): boolean {
    if (this.token) {
      try {
        var jwt = JSON.parse(atob(this.token.split('.')[1]));
        var date = Math.floor(Date.now() / 1000);
        return date < jwt.exp;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  setToken(token: string): void {
    this.token = token;
    sessionStorage.setItem('token', token);
  }

  logout(): void {
    this.token = null;
    sessionStorage.removeItem('token');
  }
}
