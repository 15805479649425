import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Router} from "@angular/router";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  base_url: string = environment.api.url;

  constructor(private http: HttpClient, private authService: AuthService, private router: Router) { }

  post(service: string, params: any, success: (res: any) => void = null, fail: (res: ErrorResponse) => void = null): Observable<any> {
    let headers: any = {};
    if (this.authService.isLoggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authService.token;
    }

    if (!params) {
      params = {};
    }
    params.__url__ = location.toString();

    let obs = this.http.post(this.getUrl(service), params, {
      headers: new HttpHeaders(headers)
    })

    obs.subscribe({
      next: (res: Response) => this.handleResponse(res, success, fail),
      error: (error: HttpErrorResponse) => this.handleResponse(error, success, fail)
    });

    return obs;
  }

  handleResponse(res: Response|HttpErrorResponse, success: (res: any) => void = null, fail: (res: ErrorResponse) => void = null) {
    if (res instanceof HttpErrorResponse) {
      if (res.error) {
        res = res.error;
      }
    }

    if ((res as any).success || (res as any).status == 'success') {
      if (success) success(res);
    } else {
      if (fail) fail(res as ErrorResponse);
      if ((res as ErrorResponse).error === 401) {
        this.authService.logout();
        this.router.navigate(['/connexion']);
      }
    }
  }

  getUrl(service: string): string {
    if (service[0] == '/') {
      return this.base_url + service;
    }
    return this.base_url + '/' + service;
  }

  logout(callback) {
    this.post('logout', {}, res => {
      this.authService.logout();
      callback();
    }, res => {
      this.authService.logout();
      callback();
    });
  }

}

export interface Response {
  success: boolean;
}

export interface ErrorResponse extends Response {
  error: number;
  message: string|null
}

